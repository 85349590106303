import '../styles/bubbleprotects.scss';

import React, { useEffect,useState } from 'react';

import Layout from '../components/layout';
import hey from '../images/calculator.png';
import waiting from '../images/Calculatorpage1.png';
import downarrow from '../images/dropdown-arrow.svg';


function BubbleProtects() {

  const [ data, setData ] = useState( {
    banner: {},
    products: [],
    tooltipbox: {}
  } );

  const [ showHi, setHi ] = useState( true );

  const [ isActive, setActive ] = useState( [] );

  const makeActive = ( i ) => {
    debugger;
    const active = isActive.map( ( value, idx ) => {
      if( idx  === i ) {
        return !value;
      } else{
        return value;
      }
    } );
    const showHi = active.every( value => value );
    setHi( showHi );
    setActive( active );
  };

  useEffect( () => {
    async function getData(){
      fetch( '/protects.json' ).then( ( resp ) => resp.json() )
        .then( body => {
          setData( body );
          setActive( body.products.map( k => true ) );
        } );
    }
    getData();
  }, [] );

  return(
    <Layout>
      <div className="banner-section" id="bubble-protects-page">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-12 col-lg-6">
              <div className="banner-details">
                <h2>{data.banner.plain}

                  <span>{data.banner.highlight}</span>
                </h2>

                <p>
                  {data.subBanner}
                </p>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-6 text-center">
              <div className="banner-card">
                <img src={showHi ? hey : waiting} alt="bannersmallimgcard" className='smallcardimg'/>

                <div className="tooltip-box">
                  <h4>{data.tooltipbox.headtext}</h4>

                  <p>{data.tooltipbox.paratext}</p>
                </div>

                <h3>{data.productHeading}</h3>

                <ul>
                  {
                    data.products.map( ( product, i ) => (
                      <li
                        onClick={ () => makeActive( i ) }
                        className={ isActive[i] ? 'active smallcard flexColumn' : 'flexColumn smallcard'}
                        key={i} >
                        <img className="img-title" src={product.src} alt={product.name} />

                        <h4>{product.name}</h4>
                      </li> ) )
                  }
                </ul>

                <button className='getstart-btn'>Get Started</button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center">
              <a href="/" className='arrowbox'>
                <img src={downarrow} alt="downarrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BubbleProtects;
